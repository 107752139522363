import Layout from '@/layouts'
/**
 * 生成路由
 * @param {Array} routerlist 格式化路由
 * @returns
 */
export function initRoute(routerlist) {
  const router = []
  try {
    routerlist.forEach((e) => {
      let e_new = {
        path: e.path ? e.path : '/',
        name: e.name ? e.name : '',
        component:
          e.component === 'layouts'
            ? Layout
            : () => import(`@/views${e.component}`),
      }
      if (e.children) {
        const children = initRoute(e.children)
        // 保存权限
        e_new = { ...e_new, children: children }
      }
      if (e.redirect) {
        e_new = { ...e_new, redirect: e.redirect ? e.redirect : 'noRedirect' }
      }
      if (e.generatemenu === 0) {
        e_new = { ...e_new, hidden: true }
      }
      if (e.meta) {
        if (e.meta.icon !== '' && e.meta.title !== '') {
          e_new = { ...e_new, meta: { title: e.meta.title, icon: e.meta.icon } }
        } else if (e.meta.title !== '' && e.meta.icon === '') {
          e_new = { ...e_new, meta: { title: e.meta.title } }
        }
      }
      router.push(e_new)
    })
  } catch (error) {
    console.error(error)
    return []
  }
  return router
}
