import Layout from '@/layouts'
// 派加云业务中台

const pagaCloudBackground = [
  {
    path: '/',
    component: Layout,
    redirect: { name: 'clueList' },
  },
  // TODO 企业
  {
    path: '/dealer',
    component: Layout,
    name: 'dealer',
    alwaysShow: false,
    meta: {
      title: '经销商管理',
      icon: 'indent',
      auth: 'dealer',
      permissions: [
        'brand_manager',
        'dealer_manager',
        'telesales',
        'viewDealers',
      ],
    },
    children: [
      {
        path: 'list',
        name: 'dealerList',
        component: () => import('@/views/dealer/index/list'),
        meta: {
          title: '经销商列表',
          icon: 'border-all',
          permissions: [
            'brand_manager',
            'dealer_manager',
            'telesales',
            'viewDealers',
          ],
        },
      },
      {
        path: 'companyList',
        name: 'companyList',
        component: () => import('@/views/company/index/list'),
        meta: {
          title: '门店列表',
          icon: 'border-all',
          permissions: ['brand_manager', 'telesales', 'viewDealers'],
        },
      },
      {
        path: 'companyList/detail',
        name: 'detail',
        component: () => import('@/views/company/index/detail'),
      },
      {
        path: 'flowList',
        name: 'flowList',
        component: () => import('@/views/dealer/index/flowList'),
      },
      {
        path: 'flowDetail',
        name: 'flowDetail',
        component: () => import('@/views/dealer/index/flowDetail'),
      },
    ],
  },
  // TODO 用户中心
  {
    path: '/user',
    component: Layout,
    redirect: 'noRedirect',
    name: 'User',
    alwaysShow: false,
    meta: {
      title: '用户中心',
      icon: 'indent',
      auth: 'user',
      permissions: ['brand_manager', 'shop_manager'],
    },
    children: [
      {
        path: 'roleList',
        name: 'roleList',
        component: () => import('@/views/user/role/list'),
        meta: {
          title: '角色列表',
          icon: 'border-all',
          permissions: ['brand_manager', 'shop_manager'],
        },
      },
      {
        path: 'departmentList',
        name: 'departmentList',
        component: () => import('@/views/department/index/list'),
        meta: {
          title: '组织架构',
          icon: 'border-all',
          permissions: ['brand_manager', 'shop_manager'],
        },
      },
      {
        path: 'cardUser',
        name: 'cardUser',
        alwaysShow: true, // 是否显示下一级
        component: () => import('@/views/user/cardUser/routerIndex'),
        meta: {
          title: '名片用户',
          icon: 'border-all',
          // permissions: ['brand_manager', 'shop_manager'],
        },
        children: [
          {
            path: 'cardUserList',
            name: 'cardUserList',
            component: () => import('@/views/user/cardUser/index'),
            meta: {
              title: '名片用户列表',
              icon: 'list-alt',
              // permissions: ['brand_manager', 'shop_manager'],
            },
          },
        ],
      },
      {
        path: 'leaveInformationUser',
        name: 'leaveInformationUser',
        alwaysShow: true, // 是否显示下一级
        component: () => import('@/views/user/cardUser/routerIndex'),
        meta: {
          title: '留资用户',
          icon: 'border-all',
          // permissions: ['brand_manager', 'shop_manager'],
        },
        children: [
          {
            path: 'leaveInformationUserList',
            name: 'leaveInformationUserList',
            component: () => import('@/views/user/leaveInformationUser/index'),
            meta: {
              title: '留资用户列表',
              icon: 'list-alt',
              // permissions: ['brand_manager', 'shop_manager'],
            },
          },
        ],
      },
      {
        path: 'list/detail',
        name: 'listDetail',
        component: () => import('@/views/user/index/detail'),
      },
      {
        path: 'roleList/detail',
        name: 'roleDetail',
        component: () => import('@/views/user/role/detail'),
      },
      {
        path: 'list/quitList',
        name: 'quitList',
        component: () => import('@/views/user/index/quitList'),
      },
    ],
  },
  // scrm 线索
  {
    path: '/scrm',
    name: 'scrm',
    component: Layout,
    redirect: 'noRedirect',
    alwaysShow: true, // 是否显示下一级
    meta: { title: 'SCRM', icon: 'indent', auth: 'clue' },
    children: [
      {
        path: 'clue',
        name: 'Clue',
        redirect: { name: 'clueList' },
        component: () => import('@/views/clue/index'),
        alwaysShow: true, // 是否显示下一级
        meta: { title: '线索', icon: 'indent' },
        children: [
          {
            path: 'list',
            name: 'clueList',
            component: () => import('@/views/clue/toc/list/index'),
            meta: {
              title: '线索列表',
              icon: 'list-alt',
              // icon: 'file-import',
              // permissions: ['common'],
            },
          },
          {
            path: 'import',
            name: 'ClueImport',
            component: () => import('@/views/clue/toc/import/index'),
            meta: {
              title: '线索导入',
              icon: 'file-import',
              permissions: ['common'],
            },
          },
        ],
      },
      {
        path: 'setting',
        name: 'Setting',
        component: () => import('@/views/clue/index'),
        alwaysShow: true,
        meta: {
          title: '设置',
          icon: 'indent',
        },
        children: [
          {
            path: 'clueSetting',
            name: 'ClueSetting',
            component: () => import('@/views/clue/setting/ClueSetting'),
            meta: {
              title: '线索设置',
              icon: 'edit',
              permissions: ['common'],
            },
          },
        ],
      },
    ],
  },
  // TODO 品牌-商品中心
  {
    path: '/packardCloudProd',
    component: Layout,
    redirect: 'noRedirect',
    name: 'packardCloudProd',
    alwaysShow: true,
    meta: {
      title: '商品中心（品牌）',
      icon: 'indent',
      permissions: ['is_brand'],
      // permissions: ['shop_goods_user', 'warehouse'],
      cache: false,
    },
    children: [
      {
        path: 'basicData',
        name: 'BasicData',
        component: () =>
          import('@/views/packardCloudProd/pages/basicData/index'),
        alwaysShow: false, // 是否显示下一级
        redirect: { name: 'ClassSettings' },
        meta: {
          title: '基本数据',
          icon: 'border-all',
          cache: false,
        },
        children: [
          {
            path: 'classSettings',
            name: 'ClassSettings',
            component: () =>
              import(
                '@/views/packardCloudProd/pages/basicData/classSettings/index'
              ),
            hidden: true,
            meta: {
              title: '分类设置',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/packardCloudProd/basicData',
            },
          },
          {
            path: 'unit',
            name: 'Unit',
            component: () =>
              import('@/views/packardCloudProd/pages/basicData/unit/index'),
            meta: {
              title: '单位',
              icon: 'file-import',
              activeMenu: '/packardCloudProd/basicData',
            },
            hidden: true,
          },
          {
            path: 'attributeParameterSetting',
            name: 'AttributeParameterSetting',
            component: () =>
              import(
                '@/views/packardCloudProd/pages/basicData/attributeParameterSetting/index'
              ),
            redirect: { name: 'AttributesList' },
            meta: {
              title: '属性参数设置',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/packardCloudProd/basicData',
            },
            hidden: true,
            children: [
              {
                path: 'attributesList',
                name: 'AttributesList',
                component: () =>
                  import(
                    '@/views/packardCloudProd/pages/basicData/attributeParameterSetting/attributesList'
                  ),
                meta: {
                  title: '自定属性列表',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                  activeMenu: '/packardCloudProd/basicData',
                },
                hidden: true,
              },
              {
                path: 'editCustomAttributes',
                name: 'EditCustomAttributes',
                component: () =>
                  import(
                    '@/views/packardCloudProd/pages/basicData/attributeParameterSetting/editCustomAttributes'
                  ),
                meta: {
                  title: '属性编辑',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                  activeMenu: '/packardCloudProd/basicData',
                },
                hidden: true,
              },
            ],
          },
        ],
      },
      {
        path: 'commodityManagement',
        name: 'CommodityManagement',
        component: () =>
          import('@/views/packardCloudProd/pages/commodityManagement/index'),
        alwaysShow: false, // 是否显示下一级
        redirect: { name: 'ProdList' },
        meta: {
          title: '商品管理',
          icon: 'border-all',
          cache: false,
        },
        children: [
          {
            path: 'prodList',
            name: 'ProdList',
            component: () =>
              import(
                '@/views/packardCloudProd/pages/commodityManagement/prodList'
              ),
            hidden: true,
            meta: {
              title: '商品列表',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/packardCloudProd/commodityManagement',
            },
          },
          {
            path: 'essentialInfo',
            name: 'EssentialInfo',
            component: () =>
              import(
                '@/views/packardCloudProd/pages/commodityManagement/essentialInfo'
              ),
            hidden: true,
            meta: {
              title: '商品基本信息',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/packardCloudProd/commodityManagement',
            },
          },
          {
            path: 'singleProduct',
            name: 'SingleProduct',
            component: () =>
              import(
                '@/views/packardCloudProd/pages/commodityManagement/singleProduct'
              ),
            hidden: true,
            meta: {
              title: '单品产品规格属性',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/packardCloudProd/commodityManagement',
            },
          },
          {
            path: 'portfolioProducts',
            name: 'PortfolioProducts',
            component: () =>
              import(
                '@/views/packardCloudProd/pages/commodityManagement/portfolioProducts'
              ),
            hidden: true,
            meta: {
              title: '组合产品规格属性',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/packardCloudProd/commodityManagement',
            },
          },
          {
            path: 'graphicDetails',
            name: 'GraphicDetails',
            component: () =>
              import(
                '@/views/packardCloudProd/pages/commodityManagement/graphicDetails'
              ),
            hidden: true,
            meta: {
              title: '商品基图文详情',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/packardCloudProd/commodityManagement',
            },
          },
        ],
      },
    ],
  },
  // TODO 门店-商品中心
  {
    path: '/shopMallProd',
    component: Layout,
    redirect: 'noRedirect',
    name: 'shopMallProd',
    alwaysShow: true,
    meta: {
      title: '商品中心（门店）',
      icon: 'indent',
      permissions: ['is_shop'],
      // permissions: ['shop_goods_user', 'warehouse'],
      cache: false,
    },
    children: [
      {
        path: 'basicData',
        name: 'BasicData',
        component: () => import('@/views/shopMallProd/pages/basicData/index'),
        alwaysShow: false, // 是否显示下一级
        redirect: { name: 'shopClassSettings' },
        meta: {
          title: '基本数据',
          icon: 'border-all',
          cache: false,
        },
        children: [
          {
            path: 'classSettings',
            name: 'shopClassSettings',
            component: () =>
              import(
                '@/views/shopMallProd/pages/basicData/classSettings/index'
              ),
            hidden: true,
            meta: {
              title: '分类设置',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/shopMallProd/basicData',
            },
          },
          // {
          //   path: 'unit',
          //   name: 'Unit',
          //   component: () =>
          //     import('@/views/shopMallProd/pages/basicData/unit/index'),
          //   meta: {
          //     title: '单位',
          //     icon: 'file-import',
          //     permissions: ['is_brand'],
          //     activeMenu: '/shopMallProd/basicData',
          //   },
          //   hidden: true,
          // },
          {
            path: 'label',
            name: 'Label',
            component: () =>
              import('@/views/shopMallProd/pages/basicData/label/index'),
            meta: {
              title: '标签',
              icon: 'file-import',
              activeMenu: '/shopMallProd/basicData',
              // auth: 'goods_unit',
            },
            hidden: true,
          },
          {
            path: 'attributeParameterSetting',
            name: 'AttributeParameterSetting',
            component: () =>
              import(
                '@/views/shopMallProd/pages/basicData/attributeParameterSetting/index'
              ),
            redirect: { name: 'shopAttributesList' },
            meta: {
              title: '属性参数设置',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/shopMallProd/basicData',
            },
            hidden: true,
            children: [
              {
                path: 'attributesList',
                name: 'shopAttributesList',
                component: () =>
                  import(
                    '@/views/shopMallProd/pages/basicData/attributeParameterSetting/attributesList'
                  ),
                meta: {
                  title: '自定属性列表',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                  activeMenu: '/shopMallProd/basicData',
                },
                hidden: true,
              },
              {
                path: 'editCustomAttributes',
                name: 'EditCustomAttributes',
                component: () =>
                  import(
                    '@/views/shopMallProd/pages/basicData/attributeParameterSetting/editCustomAttributes'
                  ),
                meta: {
                  title: '属性编辑',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                  activeMenu: '/shopMallProd/basicData',
                },
                hidden: true,
              },
            ],
          },
        ],
      },
      {
        path: 'commodityManagement',
        name: 'CommodityManagement',
        component: () =>
          import('@/views/shopMallProd/pages/commodityManagement/index'),
        alwaysShow: false, // 是否显示下一级
        redirect: { name: 'shopProdList' },
        meta: {
          title: '商品管理',
          icon: 'border-all',
          cache: false,
        },
        children: [
          {
            path: 'prodList',
            name: 'shopProdList',
            component: () =>
              import('@/views/shopMallProd/pages/commodityManagement/prodList'),
            hidden: true,
            meta: {
              title: '商品列表',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/shopMallProd/commodityManagement',
            },
          },
          {
            path: 'essentialInfo',
            name: 'EssentialInfo',
            component: () =>
              import(
                '@/views/shopMallProd/pages/commodityManagement/essentialInfo'
              ),
            hidden: true,
            meta: {
              title: '商品基本信息',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/shopMallProd/commodityManagement',
            },
          },
          {
            path: 'singleProduct',
            name: 'SingleProduct',
            component: () =>
              import(
                '@/views/shopMallProd/pages/commodityManagement/singleProduct'
              ),
            hidden: true,
            meta: {
              title: '单品产品规格属性',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/shopMallProd/commodityManagement',
            },
          },
          {
            path: 'portfolioProducts',
            name: 'PortfolioProducts',
            component: () =>
              import(
                '@/views/shopMallProd/pages/commodityManagement/portfolioProducts'
              ),
            hidden: true,
            meta: {
              title: '组合产品规格属性',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/shopMallProd/commodityManagement',
            },
          },
          {
            path: 'graphicDetails',
            name: 'GraphicDetails',
            component: () =>
              import(
                '@/views/shopMallProd/pages/commodityManagement/graphicDetails'
              ),
            hidden: true,
            meta: {
              title: '商品基图文详情',
              icon: 'file-import',
              permissions: ['common'],
              activeMenu: '/shopMallProd/commodityManagement',
            },
          },
        ],
      },
    ],
  },
  // 派家云门店采购管理
  {
    path: '/pagaCloudStorePurMgt',
    component: Layout,
    // redirect: 'noRedirect',
    name: 'PagaCloudStorePurMgt',
    alwaysShow: true,
    meta: {
      title: '采购管理（门店）',
      icon: 'indent',
      auth: 'purchase_outlet',
      permissions: ['shop_manager', 'shop_user'],
      cache: false,
    },
    children: [
      {
        path: 'purchaseOrder/purMall',
        name: 'PurMallStore',
        component: () => import('@/views/pagaCloudStorePurMgt/pages/purMall'),
        meta: {
          title: '采购商城',
          icon: 'border-all',
          cache: false,
          permissions: ['shop_manager', 'shop_user'],
          auth: 'purchase_shopping',
        },
      },
      {
        path: 'purchaseOrder/orderDetail',
        name: 'SorderDetail',
        component: () =>
          import('@/views/pagaCloudStorePurMgt/pages/orderDetail'),
        hidden: true,
        meta: {
          title: '订单详情',
          icon: 'border-all',
          permissions: ['shop_manager', 'shop_user'],
          cache: false,
        },
      },
      {
        path: 'purchaseOrder/procurementVehicle',
        name: 'ProcurementVehicleStore',
        component: () =>
          import('@/views/pagaCloudStorePurMgt/pages/procurementVehicle'),
        meta: {
          title: '采购车',
          icon: 'border-all',
          cache: false,
          permissions: ['shop_manager', 'shop_user'],
          auth: 'purchase_procurementVehicle',
        },
      },
      {
        path: 'purchaseOrder/orderList',
        name: 'OrderListStore',
        component: () => import('@/views/pagaCloudStorePurMgt/pages/orderList'),
        meta: {
          title: '采购订单',
          icon: 'border-all',
          cache: false,
          auth: 'purchase_list',
          permissions: ['shop_manager', 'shop_user'],
        },
      },
      {
        path: 'purchaseOrder/purSetting',
        name: 'PurSettingStore',
        component: () =>
          import('@/views/pagaCloudStorePurMgt/pages/purSetting'),
        meta: {
          title: '采购设置',
          auth: 'purchase_set',
          icon: 'border-all',
          permissions: ['shop_manager', 'shop_user'],
          cache: false,
        },
      },
    ],
  },
  // 派家云品牌采购管理
  {
    path: '/pagaCloudBrandPurMgt',
    component: Layout,
    redirect: 'noRedirect',
    name: 'PagaCloudBrandPurMgt',
    alwaysShow: true,
    meta: {
      title: '采购管理（品牌）',
      icon: 'indent',
      auth: 'purchasing_management',
      permissions: ['brand_manager', 'brand_user'],
      cache: false,
    },
    children: [
      {
        path: 'purchaseOrder/orderList',
        name: 'OrderList',
        component: () => import('@/views/pagaCloudBrandPurMgt/pages/orderList'),
        meta: {
          title: '采购订单',
          icon: 'border-all',
          cache: false,
          auth: 'purchasing_list',
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/shoppingMall',
        name: 'ShoppingMall',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/shoppingMall'),
        meta: {
          title: '采购商城',
          auth: 'purchasing_shopping',
          icon: 'border-all',
          cache: false,
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/orderDetail',
        name: 'PorderDetail',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/orderDetail'),
        hidden: true,
        meta: {
          title: '订单详情',
          icon: 'border-all',
          cache: false,
          activeMenu: '/pagaCloudBrandPurMgt/purchaseOrder/orderList',
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/purSetting',
        name: 'PurSetting',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/purSetting'),
        meta: {
          title: '采购设置',
          auth: 'purchasing_set',
          icon: 'border-all',
          cache: false,
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/examineOrder',
        name: 'OrderDetail',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/examineOrder'),
        hidden: true,
        meta: {
          title: '审核订单',
          icon: 'border-all',
          cache: false,
          activeMenu: '/pagaCloudBrandPurMgt/purchaseOrder/orderList',
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/expressDelivery',
        name: 'ExpressDelivery',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/expressDelivery'),
        hidden: true,
        meta: {
          title: '快速发货',
          icon: 'border-all',
          cache: false,
          activeMenu: '/pagaCloudBrandPurMgt/purchaseOrder/orderList',
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'purchaseOrder/logisticsQuickly',
        name: 'LogisticsQuickly',
        component: () =>
          import('@/views/pagaCloudBrandPurMgt/pages/logisticsQuickly'),
        hidden: true,
        meta: {
          title: '快速填写物流',
          icon: 'border-all',
          cache: false,
          activeMenu: '/pagaCloudBrandPurMgt/purchaseOrder/orderList',
          permissions: ['brand_manager', 'brand_user'],
        },
      },
      {
        path: 'logistics',
        name: 'Logistics',
        component: () => import('@/views/order/logistics/index'),
        hidden: true,
        meta: {
          title: '导入物流',
          icon: 'border-all',
        },
      },
      {
        path: 'lead',
        name: 'lead',
        component: () => import('@/views/order/logistics/fhindex'),
        hidden: true,
        meta: {
          title: '导入出库单',
          icon: 'border-all',
        },
      },
      {
        path: 'logisticsdetailList',
        name: 'logisticsdetailList',
        component: () => import('@/views/order/logistics/detailList'),
        hidden: true,
        meta: {
          title: '明细列表',
          icon: 'border-all',
        },
      },
    ],
  },

  // TODO 订单
  {
    path: '/order',
    component: Layout,
    redirect: 'noRedirect',
    name: 'Order',
    alwaysShow: true,
    meta: {
      title: '订单',
      icon: 'indent',
      permissions: ['shop_goods_user', 'warehouse'],
      // auth: 'order',
    },
    children: [
      {
        path: 'importall',
        name: 'OrderImportall',
        component: () => import('@/views/order/importall/index'),
        meta: {
          title: '全部订单导出',
          icon: 'border-all',
          // permissions: ['shop_goods_user'],
          auth: 'order_all_export',
        },
      },
      {
        path: 'importjd',
        name: 'OrderImportjd',
        component: () => import('@/views/order/importjd/index'),
        meta: {
          title: '金蝶订单导出',
          icon: 'border-all',
          permissions: ['shop_goods_user'],
          auth: 'order_jd_export',
        },
      },
      {
        path: 'imporclod',
        name: 'OrderImporclod',
        component: () => import('@/views/order/imporclod/index'),
        meta: {
          title: '云星空订单导出',
          icon: 'border-all',
          permissions: ['shop_goods_user'],
          auth: 'order_jd_export',
        },
      },
    ],
  },
  // 商品
  {
    path: '/commodity',
    component: Layout,
    redirect: 'noRedirect',
    name: 'Commodity',
    alwaysShow: false,
    meta: { title: '商品', icon: 'indent', permissions: ['shop_goods_user'] },
    children: [
      {
        path: 'import',
        name: 'CommodityImport',
        component: () => import('@/views/commodity/import/index'),
        meta: {
          title: '商品导出',
          icon: 'border-all',
          permissions: ['shop_goods_user'],
        },
      },
    ],
  },
  // 公告管理
  {
    path: '/oa',
    component: Layout,
    name: 'oa',
    alwaysShow: true,
    meta: { title: 'OA', icon: 'indent', auth: 'oa', permissions: ['common'] },
    children: [
      // 公告管理
      {
        path: 'announcement',
        component: () => import('@/views/routerIndex'),
        redirect: { name: 'announcement_list' },
        name: 'Notice',
        meta: {
          title: '公告',
          icon: 'list-alt',
          auth: 'announcement',
          permissions: ['common'],
        },
        children: [
          {
            path: 'announcement_list',
            name: 'announcement_list',
            component: () => import('@/views/notice/list/index'),
            hidden: true,
            meta: {
              // title: '公告列表',
              icon: 'list-alt',
            },
          },
          {
            path: 'announcement_detail',
            name: 'Detail',
            component: () => import('@/views/notice/detail/index'),
            hidden: true,
            meta: {
              title: '公告详情',
              icon: 'list-alt',
              // activeMenu: '/notice/list',
            },
          },
        ],
      },
    ],
  },

  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

export { pagaCloudBackground }
