/**
 * 用于创建所需的枚举值
 * ```js
 * // demo：
 * export const REFERRER_STATUS =  enumFn({
    我的推荐: 0,
    跟进中: 3,
    已进店: 6,
    已成交: 4,
    已无效: 5,
  })
 * ```
 * @template T
 * @param {T} obj
 * @returns {Record<keyof obj,any>}
 */
export function enumFn(obj) {
  const map = {}
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const element = obj[key]
      map[element] = key
      map[key] = element
    }
  }
  return map
}

export class EnumValues {
  static getNamesAndValues(e, fieldsObj) {
    return this.getNames(e).map((_name) => {
      const obj = { name: _name, value: e[_name], fields: undefined }
      if (fieldsObj) {
        obj.fields = fieldsObj[_name]
      }
      return obj
    })
  }

  static getNames(e) {
    // 枚举类型不能用number来当key，通过keys获取到的可能是数字，那么通过隐性转换来获取字符串(因为字符串转数字必然是NaN)
    return Object.keys(e).filter((key) => isNaN(+key))
  }

  static getNameFromValue(e, value) {
    const all = this.getNamesAndValues(e).filter((pair) => pair.value === value)
    return all.length === 1 ? all[0].name : null
  }

  static getValues(e) {
    return this.getNames(e).map((name) => e[name])
  }
}
