/**
 * @author https://gitee.com/chu1204505056/vue-admin-better （不想保留author可删除）
 * @description 派加云产品
 */

const state = () => ({
  prodsMes: {},
})
const getters = {}

const mutations = {
  setProdsMes(state, prodsMes) {
    console.log(prodsMes, 'vuexsetProdsMes:')
    state.prodsMes = prodsMes
  },
  initProdsMes(state) {
    state.prodsMes = {}
  },
}
const actions = {}
export default { state, getters, mutations, actions }
