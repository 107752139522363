import Store from '../store/index'
import network from '../config/net.config'
let mixin = {
  data() {
    return {
      handle_permissions: [], // 类目下分类下的操作权限列表
    }
  },
  mounted() {},
  methods: {
    // category 类目 classify 分类
    filterPermission(category, classify, thirClassify) {
      const noLowerList = Store.state.routes.noLowerList?.includes(classify)
      let v2AllAuth = JSON.parse(localStorage.getItem('v2AllAuth'))
      // 找对应类目的权限
      if (v2AllAuth && v2AllAuth.length > 0) {
        v2AllAuth.filter((firItem) => {
          console.info(firItem, '第一层')
          if (firItem.key === category && firItem?.children) {
            firItem.children.filter((secItem) => {
              console.info(secItem, '第二层')
              if (secItem.key === classify && secItem?.children) {
                if (noLowerList) {
                  this.handle_permissions = secItem.children
                }
                secItem.children.filter((thirItem) => {
                  console.info(thirItem, '第三层')
                  if (thirItem.key === thirClassify && thirItem?.children) {
                    this.handle_permissions = thirItem.children
                  }
                })
              }
            })
          }
        })
      }
      console.info(this.handle_permissions, '获取到操作权限')
    },
    /**
     * @description 是否显示权限按钮操作（是否有权限使用，或者删除等）
     * @params handle 操作
     * @params delete 删除
     * @params export 导出
     * @params import 导入
     * @params cancel 取消
     * @params validation 核销
     *
     */
    handleBtn(action) {
      let actionList = []
      this.handle_permissions.forEach((element) => {
        actionList.push(element.key)
      })
      // console.info(actionList, '这是当前页面的操作权限-----------------')
      // 测试环境开启操作权限，正式环境全部放开
      // return network.baseURL == 'https://api.t.paiplus.cn'
      //   ? actionList.includes(action)
      //   : true

      return true
    },
  },
}

export default mixin
