import Layout from '@/layouts'
// 商城管理后台

const shopMallBackground = [
  {
    path: '/',
    component: Layout,
    redirect: { name: 'MaterialPromotion' },
  },
  //企业
  {
    path: '/dealer',
    component: Layout,
    name: 'dealer',
    alwaysShow: false,
    meta: {
      title: '经销商管理',
      icon: 'indent',
      auth: 'dealer',
      permissions: [
        'brand_manager',
        'dealer_manager',
        'telesales',
        'viewDealers',
      ],
    },
    children: [
      {
        path: 'list',
        name: 'dealerList',
        component: () => import('@/views/dealer/index/list'),
        meta: {
          title: '经销商列表',
          icon: 'border-all',
          permissions: [
            'brand_manager',
            'dealer_manager',
            'telesales',
            'viewDealers',
          ],
        },
      },
      {
        path: 'companyList',
        name: 'companyList',
        component: () => import('@/views/company/index/list'),
        meta: {
          title: '门店列表',
          icon: 'border-all',
          permissions: ['brand_manager', 'telesales', 'viewDealers'],
        },
      },
      {
        path: 'companyList/detail',
        name: 'detail',
        component: () => import('@/views/company/index/detail'),
      },
      {
        path: 'flowList',
        name: 'flowList',
        component: () => import('@/views/dealer/index/flowList'),
      },
      {
        path: 'flowDetail',
        name: 'flowDetail',
        component: () => import('@/views/dealer/index/flowDetail'),
      },
    ],
  },
  // 用户中心
  {
    id: 1,
    path: '/users',
    component: Layout,
    redirect: 'noRedirect',
    name: 'User',
    alwaysShow: true,
    meta: {
      title: '用户',
      icon: 'indent',
      auth: 'users',
      permissions: ['brand_manager', 'shop_manager'],
    },
    children: [
      {
        path: 'roleList',
        name: 'roleList',
        component: () => import('@/views/user/role/list'),
        meta: {
          title: '角色列表',
          icon: 'border-all',
          permissions: ['brand_manager', 'shop_manager'],
          auth: 'mall_users',
        },
      },
      {
        path: 'departmentList',
        name: 'departmentList',
        component: () => import('@/views/department/index/list'),
        meta: {
          title: '组织架构',
          icon: 'border-all',
          permissions: ['brand_manager', 'shop_manager'],
          auth: 'mall_users',
        },
      },
      {
        path: 'mall_users',
        name: 'mall_users',
        meta: {
          title: '商城用户',
          icon: 'border-all',
          auth: 'mall_users',
          // permissions: ['brand_manager', 'shop_manager'],
        },
      },
      {
        path: 'card_users',
        name: 'cardUser',
        component: () => import('@/views/user/cardUser/routerIndex'),
        redirect: { name: 'cardUserList' },
        meta: {
          title: '名片用户',
          icon: 'border-all',
          auth: 'card_users',
          // permissions: ['brand_manager', 'shop_manager'],
        },
        children: [
          {
            path: 'card_users_list',
            name: 'cardUserList',
            component: () => import('@/views/user/cardUser/index'),
            hidden: true,
            meta: {
              title: '名片用户列表',
              icon: 'list-alt',
              // permissions: ['brand_manager', 'shop_manager'],
            },
          },
        ],
      },
      {
        path: 'message_users',
        name: 'leaveInformationUser',
        component: () => import('@/views/user/cardUser/routerIndex'),
        redirect: { name: 'leaveInformationUserList' },
        meta: {
          title: '留资用户',
          icon: 'border-all',
          auth: 'message_users',
          // permissions: ['brand_manager', 'shop_manager'],
        },
        children: [
          {
            path: 'message_users_list',
            name: 'leaveInformationUserList',
            component: () => import('@/views/user/leaveInformationUser/index'),
            hidden: true,
            meta: {
              title: '留资用户列表',
              icon: 'list-alt',
              // permissions: ['brand_manager', 'shop_manager'],
            },
          },
        ],
      },
      {
        path: 'list/detail',
        name: 'listDetail',
        component: () => import('@/views/user/index/detail'),
      },
      {
        path: 'roleList/detail',
        name: 'roleDetail',
        component: () => import('@/views/user/role/detail'),
      },
      {
        path: 'list/quitList',
        name: 'quitList',
        component: () => import('@/views/user/index/quitList'),
      },
    ],
  },
  // 内容中心
  {
    path: '/content',
    component: Layout,
    name: 'Content',
    alwaysShow: true,
    meta: {
      title: '内容',
      icon: 'indent',
      // permissions: ['common'],
      auth: 'content',
      // cache: false,
    },
    children: [
      {
        path: 'case',
        name: 'case',
        redirect: 'noRedirect',
        component: () => import('@/views/routerIndex'),
        alwaysShow: true,
        meta: {
          title: '案例',
          icon: 'indent',
          auth: 'case',
        },
        children: [
          {
            path: 'master_case',
            name: 'master_case',
            component: () => import('@/views/designerCase/index'),
            redirect: { name: 'DesignerCaseList' },
            meta: {
              title: '设计师案例',
              icon: 'indent',
              auth: 'master_case',
            },
            children: [
              {
                path: 'list',
                name: 'DesignerCaseList',
                alwaysShow: true,
                component: () =>
                  import('@/views/designerCase/designerCaseList/index'),
                hidden: true,
                meta: {
                  title: '设计师案例列表',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                },
              },
              {
                path: 'add',
                name: 'DesignerCaseAdd',
                alwaysShow: true,
                hidden: true,
                component: () =>
                  import('@/views/designerCase/designerCaseAdd/index'),
                hidden: true,
                meta: {
                  title: '新建',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                },
              },
            ],
          },
          {
            path: 'local_case',
            name: 'local_case',
            component: () => import('@/views/caseManage/index'),
            redirect: { name: 'CaseManageList' },
            meta: {
              title: '实景案例',
              icon: 'indent',
              auth: 'local_case',
            },
            children: [
              {
                path: 'list',
                name: 'CaseManageList',
                // alwaysShow: true,
                component: () =>
                  import('@/views/caseManage/caseManageList/index'),
                hidden: true,
                meta: {
                  title: '案例列表',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                },
              },
              {
                path: 'add',
                name: 'CaseManageAdd',
                // alwaysShow: true,
                hidden: true,
                component: () =>
                  import('@/views/caseManage/caseManageAdd/index'),
                meta: {
                  title: '新增案例',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                },
              },
            ],
          },
          {
            path: 'show_home',
            name: 'HomePolite',
            redirect: { name: 'ActivitiesList' },
            component: () => import('@/views/homePolite/index'),
            alwaysShow: false,
            meta: {
              title: '晒家有礼',
              icon: 'cogs',
              permissions: ['common'],
              auth: 'show_home',
            },
            children: [
              {
                path: 'show_home_list',
                name: 'ActivitiesList',
                component: () => import('@/views/homePolite/activities/index'),
                hidden: true,
                meta: {
                  title: '',
                  icon: 'border-all',
                  permissions: ['common'],
                },
              },
              // {
              //   path: 'show_home_auditWork',
              //   name: 'AuditWorkList',
              //   component: () => import('@/views/homePolite/auditWork/index'),
              //   meta: {
              //     title: '作品审核',
              //     icon: 'border-all',
              //     permissions: ['common'],
              //   },
              // },
              {
                path: 'winnerList',
                name: 'WinnerList',
                component: () => import('@/views/homePolite/winnerList/index'),
                hidden: true,
                meta: {
                  title: '作品审核',
                  icon: 'border-all',
                  permissions: ['common'],
                },
              },
            ],
          },
        ],
      },
      {
        path: 'material',
        name: 'material',
        redirect: 'noRedirect',
        component: () => import('@/views/routerIndex'),
        alwaysShow: true,
        meta: {
          title: '素材',
          icon: 'indent',
          auth: 'material',
        },
        children: [
          {
            path: 'article',
            name: 'article',
            component: () => import('@/views/routerIndex'),
            redirect: { name: 'articleList' },
            meta: {
              title: '文章',
              icon: 'indent',
              permissions: ['common'],
              auth: 'article',
            },
            children: [
              {
                path: 'articleList',
                name: 'articleList',
                component: () => import('@/views/articleManagement/index'),
                hidden: true,
              },
              {
                path: 'publishArticle',
                name: 'PublishArticle',
                meta: { title: '发布文章', icon: 'indent' },
                hidden: true,
                component: () =>
                  import('@/views/articleManagement/publishArticle'),
              },
            ],
          },
          {
            path: 'materialPromotion',
            name: 'MaterialPromotion',
            meta: {
              title: '推广物料',
              icon: 'indent',
              permissions: ['common'],
              auth: 'picture',
            },
            component: () => import('@/views/materialPromotion/index'),
          },
          {
            path: 'video',
            name: 'video',
            component: () => import('@/views/video/index'),
            redirect: { name: 'videoList' },
            meta: {
              title: '视频',
              icon: 'border-all',
              auth: 'video',
            },
            children: [
              {
                path: 'videoList',
                name: 'videoList',
                component: () => import('@/views/video/videoManage/index'),
                hidden: true,
              },
              {
                path: 'edit',
                name: 'editVideo',
                component: () => import('@/views/video/videoManage/editVideo'),
                meta: {
                  title: '发布视频',
                  icon: 'border-all',
                  permissions: ['common'],
                  cache: false,
                },
                hidden: true,
              },
            ],
          },
          // {
          //   path: 'vr',
          //   name: 'vr',
          //   component: () => import('@/views/vr/index'),
          //   redirect: { name: 'vrList' },
          //   meta: {
          //     title: 'VR',
          //     icon: 'border-all',
          //     permissions: ['common'],
          //     auth: 'vr',
          //     cache: false,
          //   },
          //   children: [
          //     {
          //       path: 'list',
          //       name: 'vrList',
          //       component: () => import('@/views/vr/vrManage/index'),
          //       hidden: true,
          //     },
          //     {
          //       path: 'edit',
          //       name: 'editVr',
          //       component: () => import('@/views/vr/vrManage/editVr'),
          //       meta: {
          //         title: '发布VR',
          //         icon: 'border-all',
          //         permissions: ['common'],
          //         cache: false,
          //       },
          //       hidden: true,
          //     },
          //   ],
          // },
        ],
      },
      {
        path: 'words',
        name: 'VerbalTrick',
        component: () => import('@/views/verbalTrick/index'),
        alwaysShow: true, // 是否显示下一级
        meta: {
          title: '话术库',
          icon: 'border-all',
          cache: false,
          auth: 'words',
        },
        children: [
          {
            path: 'words_list',
            name: 'VerbalTrickAdministrative',
            // alwaysShow: true,
            component: () =>
              import('@/views/verbalTrick/verbalTrickAdministrative/index'),
            meta: {
              title: '话术列表',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_list',
              cache: false,
            },
          },
          {
            path: 'words_category',
            name: 'VerbalTrickClassify',
            // alwaysShow: true,
            component: () =>
              import('@/views/verbalTrick/verbalTrickClassify/index'),
            meta: {
              title: '话术分类',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_category',
              cache: false,
            },
          },
        ],
      },
      {
        path: 'essentialData',
        name: 'EssentialData',
        component: () => import('@/views/essentialData/index'),
        alwaysShow: true, // 是否显示下一级
        meta: {
          title: '基础数据',
          icon: 'border-all',
          cache: false,
          auth: 'words',
        },
        children: [
          {
            path: 'articleClassification',
            name: 'ArticleClassification',
            // alwaysShow: true,
            component: () =>
              import('@/views/essentialData/articleClassification/index'),
            meta: {
              title: '文章分类',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_category',
              cache: false,
            },
          },
          {
            path: 'interspace',
            name: 'Interspace',
            // alwaysShow: true,
            component: () => import('@/views/essentialData/interspace/index'),
            meta: {
              title: '空间',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_category',
              cache: false,
            },
          },
          {
            path: 'styleList',
            name: 'StyleList',
            // alwaysShow: true,
            component: () => import('@/views/essentialData/styleList/index'),
            meta: {
              title: '风格',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_category',
              cache: false,
            },
          },
          {
            path: 'propertyList',
            name: 'PropertyList',
            // alwaysShow: true,
            component: () => import('@/views/essentialData/propertyList/index'),
            meta: {
              title: '楼盘列表',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'words_category',
              cache: false,
            },
          },
        ],
      },
    ],
  },
  // 营销中心
  {
    path: '/market',
    component: Layout,
    redirect: 'noRedirect',
    name: 'Marketing',
    alwaysShow: true, // 是否显示下一级
    meta: { title: '营销', icon: 'indent', auth: 'marketing' },
    children: [
      {
        path: 'marketing',
        name: 'marketing',
        component: () => import('@/views/routerIndex'),
        alwaysShow: true,
        meta: {
          title: '营销玩法',
          icon: 'indent',
          auth: 'marketing',
        },
        children: [
          {
            path: 'red_envelope',
            name: 'Redpacket',
            component: () => import('@/views/market/index'),
            redirect: { name: 'redpacketList' },
            meta: {
              title: '红包拉新',
              icon: 'list-alt',
              permissions: ['common'],
              auth: 'red_envelope',
            },
            children: [
              {
                path: 'redpacketList',
                name: 'redpacketList',
                component: () => import('@/views/market/redpacket/index'),
                meta: {
                  title: '活动列表',
                  icon: 'list-alt',
                  permissions: ['common'],
                },
                hidden: true,
              },
              {
                path: 'newRedpacket',
                name: 'newRedpacket',
                component: () => import('@/views/market/redpacket/edit'),
                meta: {
                  title: '新建',
                  icon: 'list-alt',
                  permissions: ['common'],
                },
                hidden: true,
              },
            ],
          },
          {
            path: 'haggle',
            component: () => import('@/views/seckill/index'),
            redirect: { name: 'BargainList' },
            name: 'seckill',
            alwaysShow: false,
            meta: {
              title: '砍价',
              icon: 'cogs',
              permissions: ['common'],
              auth: 'haggle',
            },
            children: [
              {
                path: 'haggle_list',
                name: 'BargainList',
                component: () => import('@/views/seckill/bargainList'),
                meta: {
                  title: '砍价列表',
                  icon: 'border-all',
                  permissions: ['common'],
                  auth: 'haggle_list',
                },
              },
              {
                path: 'user_list',
                name: 'SeckillList',
                component: () => import('@/views/seckill/seckillList'),
                meta: {
                  title: '参与用户列表',
                  icon: 'border-all',
                  permissions: ['common'],
                  auth: 'user_list',
                },
              },
              {
                path: 'haggle_create',
                name: 'CreateActivities',
                component: () => import('@/views/seckill/createActivities'),
                hidden: true,
                meta: {
                  title: '创建活动',
                  icon: 'border-all',
                  permissions: ['common'],
                },
              },
              {
                path: 'haggle_detail',
                name: 'BargainDetails',
                component: () => import('@/views/seckill/bargainDetails'),
                hidden: true,
                meta: {
                  title: '砍价详情',
                  icon: 'border-all',
                  permissions: ['common'],
                },
              },
              {
                path: 'verification_record',
                name: 'CancelVerificationList',
                component: () =>
                  import('@/views/seckill/cancelVerificationList'),
                meta: {
                  title: '核销记录',
                  icon: 'border-all',
                  permissions: ['common'],
                  auth: 'verification_record',
                },
              },
            ],
          },
        ],
      },
      {
        path: 'anti_fake',
        name: 'antifake',
        component: () => import('@/views/caseManage/index'),
        alwaysShow: true,
        redirect: 'noRedirect',
        meta: {
          title: '防伪',
          icon: 'indent',
          auth: 'anti_fake',
          permissions: ['common'],
        },
        children: [
          {
            path: 'certificate',
            name: 'certificateList',
            component: () => import('@/views/antifake/certificateList/index'),
            meta: {
              title: '合格证',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'certificate',
            },
          },
          {
            path: 'anti_fake_list',
            name: 'fakeList',
            component: () => import('@/views/antifake/fakeList/index'),
            meta: {
              title: '打假名单',
              icon: 'border-all',
              permissions: ['common'],
              auth: 'anti_fake_list',
            },
          },
        ],
      },
    ],
  },
  // 品牌中心
  {
    path: '/brand',
    component: Layout,
    redirect: 'noRedirect',
    name: 'Brand',
    alwaysShow: true,
    meta: {
      title: '品牌',
      icon: 'indent',
      // auth: 'brand',
      permissions: ['brand_manager', 'shop_manager'],
    },
    children: [
      {
        path: 'brand_list',
        name: 'Brand_list',
        component: () => import('@/views/shopMallProd/pages/index'),
        redirect: { name: 'BrandListIndex' },
        meta: {
          title: '品牌管理',
          icon: 'border-all',
          auth: 'brand_list',
        },
        children: [
          {
            path: 'brandListIndex',
            name: 'BrandListIndex',
            component: () => import('@/views/shopMallProd/pages/brand/index'),
            hidden: true,
            meta: {
              title: '品牌管理列表',
              icon: 'list-alt',
            },
          },
        ],
      },
    ],
  },
  // 订单
  {
    path: '/order',
    component: Layout,
    redirect: 'noRedirect',
    name: 'Order',
    alwaysShow: true,
    meta: {
      title: '订单中心',
      icon: 'indent',
      // permissions: ['shop_goods_user', 'warehouse'],
      auth: 'order',
    },
    children: [
      {
        path: 'offlineOrder',
        name: 'OfflineOrder',
        component: () => import('@/views/order/shoppingOrder/offlineOrder'),
        meta: {
          title: '线下核销订单',
          icon: 'border-all',
          // permissions: ['common'],
          auth: 'offline_order',
        },
      },
      {
        path: 'offlineOrderDetails',
        name: 'OfflineOrderDetails',
        component: () =>
          import('@/views/order/shoppingOrder/offlineOrderDetails'),
        meta: {
          title: '订单详情',
          icon: 'border-all',
        },
        hidden: true,
      },
    ],
  },
  // 设置
  {
    path: '/shoppingSet',
    component: Layout,
    redirect: 'noRedirect',
    name: 'ShoppingSet',
    alwaysShow: true,
    meta: {
      title: '设置',
      icon: 'indent',
      auth: 'shopping_set',
    },
    children: [
      {
        path: 'offlineOrderSet',
        name: 'OfflineOrderSet',
        component: () => import('@/views/order/shoppingOrder/offlineOrderSet'),
        meta: {
          title: '订单设置',
          icon: 'border-all',
          auth: 'offline_order_set',
        },
      },
    ],
  },
  // TODO 结算管理
  {
    path: '/settlement',
    component: Layout,
    redirect: '/settlement/list',
    name: 'settlement',
    alwaysShow: true, // 是否显示下一级
    meta: { title: '结算管理', icon: 'indent' },
    children: [
      {
        path: 'wallet',
        name: 'Wallet',
        component: () => import('@/views/settlement/wallet'),
        meta: {
          title: '商户钱包管理',
          icon: 'list-alt',
        },
      },
      {
        path: 'order',
        name: 'Order',
        component: () => import('@/views/settlement/order'),
        meta: {
          title: '订单管理',
          icon: 'list-alt',
        },
      },
    ],
  },
  {
    path: '*',
    redirect: '/404',
    hidden: true,
  },
]

export { shopMallBackground }
