/**
 * @description 判读是否为外链
 * @param path
 * @returns {boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
/**
 *
 * @description 校验密码是否小于6位
 * @param str
 * @returns {boolean}
 */
export function isPassword(str) {
  return str.length >= 6
}
/**
 * @description 判断是否是字符串
 * @param str
 * @returns {boolean}
 */
export function isString(str) {
  return typeof str === 'string' || str instanceof String
}
/**
 * @description 判断是否是数组
 * @param arg
 * @returns {arg is any[]|boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]'
  }
  return Array.isArray(arg)
}
/**
 * @description 判断是否是手机号
 * @param str
 * @returns {boolean}
 */
export function isPhone(str) {
  const reg = /^1\d{10}$/
  return reg.test(str)
}

/**
 * 检查是否卡百利企业
 * @param {*} companyId
 * @returns
 */
export function checkIsKabelCompany(companyId) {
  return companyId == 10000
}

/**
 * @description 级联选择器 三级
 * @param
 * @returns {}
 */
export function handleCascaderProps(propsOne, propsTwo, PropsThree) {
  const temporaryProps = {
    lazy: true,
    multiple: true,
    value: 'id',
    label: 'name',
    children: 'children',
    lazyLoad(node, resolve) {
      const { level } = node
      setTimeout(() => {
        switch (level) {
          case 0: {
            const nodes = propsOne.value
            resolve(nodes)
            break
          }

          case 1: {
            const nodesOne = propsTwo.value.filter(
              (item) => item.id === node.value
            )
            resolve(nodesOne)
            break
          }

          case 2: {
            const nodesTwo = PropsThree.value.filter(
              (item) => item.parent_id === node.value
            )
            resolve(nodesTwo)
            break
          }

          case 3: {
            resolve([])
            break
          }

          default:
            break
        }
      }, 30)
    },
  }
  return temporaryProps
}

/**
 * @description 时间格式化 2022-10-10
 * @param
 * @returns {}
 */

export function formatDate(date, isDeepTime) {
  const paddNum = (num) => {
    num = String(num)
    return num.replace(/^(\d)$/, '0$1')
  }
  if (isDeepTime) {
    const year = date.getFullYear()
    const month = paddNum(date.getMonth() + 1)
    const day = paddNum(date.getDate())
    const hh = paddNum(date.getHours()) // 时
    const mm = paddNum(date.getMinutes()) // 分
    const ss = paddNum(date.getSeconds()) // 秒
    const time = `${year}-${month}-${day} ${hh}:${mm}:${ss}`
    return time
  }
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const time = `${year}-${month}-${day}`
  return time
}

/**
 * @description 数字    fixedNum保留小数
 * @param
 * @returns {}
 */
export function formatPrice(num, fixedNum) {
  let temporaryNum = num
  if (temporaryNum[0] === '.') {
    temporaryNum = '00' + temporaryNum
  }
  temporaryNum = Number(temporaryNum).toFixed(fixedNum).toString()
  return temporaryNum
}

// 加强版  首次立即执行
export function debounceFun(fn, delay, immediate = true) {
  let timer
  const delayTime = delay || 1000
  return function () {
    if (timer) clearTimeout(timer)
    if (immediate) {
      const bool = !timer
      timer = setTimeout(() => (timer = 0), delayTime)
      return bool && fn.apply(this, [...arguments])
    }
    timer = setTimeout(() => fn.apply(this, [...arguments]), delayTime)
  }
}

/**
 * @description 对比数组找不同,
 * @param {Array} arr1, // 数组
 * @param {Array} arr2 // 数组
 * @todo 用法：[1,2,3] [2,3] 找出  1
 */
export function getArrDifference(arr1, arr2) {
  return arr1.concat(arr2).filter((v, i, arr) => {
    return arr.indexOf(v) === arr.lastIndexOf(v)
  })
}

/**
 * @description 对比数组找相同,
 * @param {Array} arr1, // 数组
 * @param {Array} arr2 // 数组
 * @todo 用法：[1,2,3] [2,3] 找出  [2,2,3,3]
 */
export function getArrIdentical(arr1, arr2) {
  return arr1.concat(arr2).filter((v, i, arr) => {
    return arr.indexOf(v) !== arr.lastIndexOf(v)
  })
}
// 判断是否是管理员
export function isManage() {
  let info = JSON.parse(localStorage.getItem('user-info'))
  if ([1, 2, 3].includes(info.role)) {
    return true
  }
  return false
}
