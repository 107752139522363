<template>
  <div id="vue-admin-beautiful">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'App',
    data() {
      return {
        timer: '',
      }
    },
    created() {
      console.info(this.$route, '路由的信息')
    },
    mounted() {
      const areadyBackStage = localStorage.getItem('backstage')
      if (areadyBackStage) {
        this.timer = setInterval(() => {
          if (!localStorage.getItem('backstage')) {
            window.location.href = '/'
            clearInterval(this.timer)
          }
        }, 1000)
      }
    },
    methods: {},
  }
</script>
<style lang="scss">
  .el-picker-panel.no-atTheMoment {
    .el-button--text.el-picker-panel__link-btn {
      display: none;
    }
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
</style>
