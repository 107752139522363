import request from '@/utils/request'
import { parseTime } from '@/utils/index'
/**
 * 企业切换列表
 * @param {*} params
 * @returns
 */
export function switchList() {
  return request({
    url: '/account/company/index/switchList',
    method: 'get',
  })
}

/**
 * 企业切换操作
 * @param {*} data
 * @returns
 */
export function handleSwitch(data) {
  return request({
    url: '/account/company/index/switch',
    method: 'post',
    data,
  })
}

/**
 * 下游列表
 * @document http://yapi.kabel.work/project/619/interface/api/31190
 * @param {*} params
 * @returns
 */
export function getDownStreamList(params) {
  return request({
    url: '/account/company/dealer/downstreamList',
    method: 'get',
    params,
  })
}

/**
 * 经销商列表
 * @document http://yapi.kabel.work/project/619/interface/api/28283
 * @param {*} params
 * @returns
 */
export function getDealerList(params) {
  return request({
    url: '/account/company/dealer/list',
    method: 'get',
    params,
  })
}

/**
 * 经销商详细
 * @document http://yapi.kabel.work/project/619/interface/api/28292
 * @param {*} params
 * @returns
 */
export function getDealerDetail(params) {
  return request({
    url: '/account/company/dealer/detail',
    method: 'get',
    params,
  })
}

/**
 * 经销商详细
 * @document http://yapi.kabel.work/project/619/interface/api/28292
 * @param {*} params
 * @returns
 */
export function addOrUpdateDealer(params) {
  return request({
    url: '/account/company/dealer/addOrUpdate',
    method: 'post',
    params,
  })
}

/**
 * 下游列表
 * @document http://yapi.kabel.work/project/619/interface/api/31518
 * @param {*} params
 * @returns
 */
export function getBrandShopList(params) {
  return request({
    url: '/account/company/index/getBrandShopList',
    method: 'get',
    params,
  })
}

/**
 * 企业详细
 * @document http://yapi.kabel.work/project/619/interface/api/31518
 * @param {*} params
 * @returns
 */
export function getDetail(params) {
  return request({
    url: '/account/company/index/details',
    method: 'get',
    params,
  })
}

/**
 * 企业注销
 * @document http://yapi.kabel.work/project/619/interface/api/26692
 * @param {*} params
 * @returns
 */
export function cancel(params) {
  return request({
    url: '/account/company/index/cancel',
    method: 'post',
    params,
  })
}

/**
 * 品牌商修改门店信息
 * @document http://yapi.kabel.work/project/619/interface/api/28328
 * @param {*} params
 * @returns
 */
export function addOrUpdateStore(params) {
  return request({
    url: '/account/company/dealer/addOrUpdateStore',
    method: 'post',
    params,
  })
}

/**
 * 充值、扣款科目列表
 * @document http://yapi.kabel.work/project/619/interface/api/31542
 * @param {*} params
 * @returns
 */
export function getFlowType(params) {
  return request({
    url: '/account/company/deposit/flowType',
    method: 'get',
    params,
  })
}

/**
 * 充值、扣款提交
 * @document http://yapi.kabel.work/project/619/interface/api/31542
 * @param {*} params
 * @returns
 */
export function preDepositRecharge(params) {
  return request({
    url: '/account/company/deposit/preDepositRecharge',
    method: 'post',
    params,
  })
}

/**
 * 流水明细记录列表
 * @document http://yapi.kabel.work/project/619/interface/api/27365
 * @param {*} params
 * @returns
 */
export function getFlowList(params) {
  return request({
    url: '/account/company/deposit/list',
    method: 'get',
    params,
  })
}

/**
 * 流水明细记录列表
 * @document http://yapi.kabel.work/project/619/interface/api/27356
 * @param {*} params
 * @returns
 */
export function getFlowDetail(params) {
  return request({
    url: '/account/company/deposit/detail',
    method: 'get',
    params,
  })
}

/**
 * 审核提交
 * @document http://yapi.kabel.work/project/619/interface/api/31318
 * @param {*} params
 * @returns
 */
export function audit(params) {
  return request({
    url: '/account/company/deposit/audit',
    method: 'post',
    params,
  })
}

/**
 * 经销商删除
 * @document http://yapi.kabel.work/project/619/interface/api/31318
 * @param {*} params
 * @returns
 */
export function dealerDelete(params) {
  return request({
    url: '/account/company/dealer/delete',
    method: 'post',
    params,
  })
}

/**
 * 经销商状态更新
 * @document http://yapi.kabel.work/project/619/interface/api/32894
 * @param {*} params
 * @returns
 */
export function dealerUpdateStatus(params) {
  return request({
    url: '/account/company/dealer/updateStatus',
    method: 'post',
    params,
  })
}

/**
 * 行业列表
 * @document http://yapi.kabel.work/project/619/interface/api/26440
 * @param {*} params
 * @returns
 */
export function getIndustryList(params) {
  return request({
    url: '/account/company/index/industryList',
    method: 'get',
    params,
  })
}

/**
 * 物流公司列表
 * @document http://yapi.kabel.work/project/619/interface/api/32022
 * @param {*} params
 * @returns
 */
export function getLogisticsList(params) {
  return request({
    url: '/account/company/logistics/list',
    method: 'get',
    params,
  })
}

/**
 * 上传导入的excel表格
 * @param {File} formData
 * @returns
 */
export function uploadImportExcel(formData, type = 1) {
  return request({
    url: '/account/company/deposit/import?type=' + type,
    method: 'post',
    data: formData,
    timeout: 10000,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

/**
 * excel导出
 * @param {*} params
 * @returns
 */
export function exportExcel(params) {
  return request({
    url: '/account/company/dealer/exportList',
    method: 'get',
    params,
    timeout: 20000,
    responseType: 'blob',
    fileName: `经销商数据-${parseTime(new Date(), '{y}-{m}-{d}')}.xlsx`,
  })
}
/**
 * excel导出
 * @param {*} params
 * @returns
 */
export function exportExcelM(params) {
  return request({
    url: '/account/company/index/export',
    method: 'get',
    params,
    timeout: 20000,
    responseType: 'blob',
    fileName: `门店列表-${parseTime(new Date(), '{y}-{m}-{d}')}.xlsx`,
  })
}
