import request from '@/utils/request'

export function getRouterList(data) {
  return request({
    url: '/menu/navigate',
    method: 'post',
    data,
  })
}

// 功能权限列表
export function v2getPermissionsList(params) {
  return request({
    url: '/account/user/role/v2/getPermissionsList',
    method: 'get',
    params,
  })
}

// 我的应用列表
export function v2getMyapplication(params) {
  return request({
    url: '/account/app/index/getMyList',
    method: 'get',
    params,
  })
}

// 应用切换
export function v2switchMyapplication(data) {
  return request({
    url: '/account/app/index/replace',
    method: 'post',
    data,
  })
}

// 获取登录用户菜单功能权限列表
export function v2getUserMenuList(params) {
  return request({
    // url: '/account/user/role/v2/getUserMenuList',
    url: '/account/user/role/v2/getPcUserMenuLis',
    method: 'get',
    params,
  })
}

// 获取PC端登录用户菜单功能权限列表
export function getPcMenuLis() {
  return request({
    // url: '/account/user/role/v2/getUserMenuList',
    url: '/account/user/role/v2/getPcMenuLis',
    method: 'get',
  })
}
