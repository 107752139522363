import Vue from 'vue'
import App from './App'
import store from './store'
import router from './router'
import mixin from './utils/mixin'
Vue.mixin(mixin)
// import VueVideoPlayer from 'vue-video-player'
// require videojs style
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
import './plugins'
import '@/layouts/export'
import ElementUI from 'element-ui'
ElementUI.Dialog.props.lockScroll.default = false

Vue.config.productionTip = false

new Vue({
  el: '#vue-admin-beautiful',
  router,
  store,
  render: (h) => h(App),
})
