import request from '@/utils/request'
import { encryptedData } from '@/utils/encrypt'
import { loginRSA, tokenName } from '@/config'
import { parseTime } from '@/utils/index'

export async function login(data) {
  data.app_product_id = 4
  if (loginRSA) {
    data = await encryptedData(data)
  }
  return request({
    url: '/account/user/auth/login',
    method: 'post',
    data,
  })
}

export function logout() {
  return request({
    url: '/account/user/auth/logout',
    method: 'get',
  })
}

/**
 * 获取用户列表
 * @document http://yapi.kabel.work/project/619/interface/api/25464
 * @param {JSON} params
 *    size
 *    page
 *    search_val: 关键词搜索（手机号，用户名，昵称）
 *    status: 状态：1在职（默认） 2离职
 *    department_id: 部门ID
 * @returns
 */
export function getUserPageList(params) {
  return request({
    url: '/account/user/index/list',
    method: 'get',
    params,
  })
}

/**
 * 获取部门列表
 * @param {JSON} params
 *    search_val：关键词搜索
 * @returns
 */
export function getDepartmentList(params) {
  return request({
    url: '/account/user/department/list',
    method: 'get',
    params,
  })
}

export function register() {
  return request({
    url: '/register',
    method: 'post',
  })
}

/**
 * 忘记密码
 * @links http://yapi.kabel.work/project/619/interface/api/26059
 * @returns
 */
export function forgotPassword(data) {
  return request({
    url: '/account/user/index/forgotPassword',
    method: 'post',
    data,
  })
}

/**
 * 获取用户详细
 * @links http://yapi.kabel.work/project/619/interface/api/25029
 * @returns
 */
export function getDetail(params) {
  return request({
    url: '/account/user/index/details',
    method: 'get',
    params,
  })
}

/**
 * 添加编辑用户
 * @links http://yapi.kabel.work/project/619/interface/api/25459
 * @returns
 */
export function addOrUpdate(params) {
  return request({
    url: '/account/user/index/addOrUpdate',
    method: 'post',
    params,
  })
}

/**
 * 更新用户状态（离职/恢复在职）
 * @links http://yapi.kabel.work/project/619/interface/api/26710
 * @returns
 */
export function updateStatus(params) {
  return request({
    url: '/account/user/index/updateStatus',
    method: 'post',
    params,
  })
}

/**
 * 获取离职人员列表
 * @links http://yapi.kabel.work/project/619/interface/api/25029
 * @returns
 */
export function resignedList(params) {
  return request({
    url: '/account/user/index/resignedList',
    method: 'get',
    params,
  })
}

/**
 * 用户列表
 * @links http://yapi.kabel.work/project/805/interface/api/35777
 * @returns
 */
export function getcardUserList(params) {
  return request({
    url: '/account/card/user/getPageList',
    method: 'get',
    params,
  })
}

/** 留咨用户列表
 * @returns
 */
export function getLeavedUserList(params) {
  return request({
    url: '/master/appointment/index/getList',
    method: 'get',
    params,
  })
}

/** 留咨用户详情
 * @returns
 */
export function getLeavedUserDetail(params) {
  return request({
    url: '/master/appointment/index/detail',
    method: 'get',
    params,
  })
}

/**
 * 留咨excel导出
 * @param {*} params
 * @returns
 */
export function exportExcel(params) {
  return request({
    url: '/master/appointment/index/export',
    method: 'get',
    params,
    timeout: 20000,
    responseType: 'blob',
    fileName: `留咨列表-${parseTime(new Date(), '{y}-{m}-{d}')}.xlsx`,
  })
}
