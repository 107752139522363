/**
 * @description all模式渲染后端返回路由
 * @param constantRoutes
 * @returns {*}
 */
export function convertRouter(asyncRoutes) {
  return asyncRoutes.map((route) => {
    if (route.component) {
      if (route.component === 'Layout') {
        route.component = (resolve) => require(['@/layouts'], resolve)
      } else if (route.component === 'EmptyLayout') {
        route.component = (resolve) =>
          require(['@/layouts/EmptyLayout'], resolve)
      } else {
        const index = route.component.indexOf('views')
        const path =
          index > 0 ? route.component.slice(index) : `views/${route.component}`
        route.component = (resolve) => require([`@/${path}`], resolve)
      }
    }
    if (route.meta) {
      console.log(
        route.meta.set('icon', ''),
        '渲染后看看是否meta里面有没有icon'
      )
    }
    if (route.children && route.children.length)
      route.children = convertRouter(route.children)
    if (route.children && route.children.length === 0) delete route.children
    return route
  })
}

/**
 * @description 判断当前路由是否包含权限
 * @param permissions
 * @param route
 * @returns {boolean|*}
 */
function hasPermission(permissions, route) {
  if (route.meta && route.meta.permissions) {
    return permissions.some((role) => route.meta.permissions.includes(role))
  } else {
    return true
  }
}

/**
 *
 * @description intelligence模式根据permissions数组拦截路由
 * @param routes
 * @param permissions
 * @returns {[]}
 */
export function filterAsyncRoutes(routes, permissions) {
  const finallyRoutes = []
  routes.forEach((route) => {
    const item = { ...route }
    if (hasPermission(permissions, item)) {
      if (item.children) {
        item.children = filterAsyncRoutes(item.children, permissions)
      }
      finallyRoutes.push(item)
    }
  })
  // console.info(finallyRoutes, 'llllll')
  return finallyRoutes
}

/**
 *
 * @description 解决后台报错icon问题
 * @param routes
 * @returns {[]}
 */
export function filterRoutesIcon(routes) {
  const finallyRoutes = []
  routes.forEach((route) => {
    if (!route.meta) {
      route.meta = { icon: 'indent', title: '--' }
      if (route.children) {
        filterRoutesIcon(route.children)
      }
    }
    finallyRoutes.push(route)
  })
  return finallyRoutes
}

/**
 *
 * @description 测试环境清除所有权限
 * @param routes
 * @returns {[]}
 */
export function clearRoutesPermissions(routes) {
  const finallyRoutes = []
  routes.forEach((route) => {
    if (route.meta && route.meta.permissions) {
      delete route.meta.permissions
      if (route.children) {
        clearRoutesPermissions(route.children)
      }
    }
    finallyRoutes.push(route)
  })
  return finallyRoutes
}
