import { storage, userTableName } from '@/config'

/**
 * @description 获取用户信息
 * @returns {string|ActiveX.IXMLDOMNode|Promise<any>|any|IDBRequest<any>|MediaKeyStatus|FormDataEntryValue|Function|Promise<Credential | null>}
 */
export function getUserInfo() {
  if (storage) {
    if ('localStorage' === storage) {
      return JSON.parse(localStorage.getItem(userTableName))
    } else if ('sessionStorage' === storage) {
      return JSON.parse(sessionStorage.getItem(userTableName))
    } else {
      return JSON.parse(localStorage.getItem(userTableName))
    }
  } else {
    return JSON.parse(localStorage.getItem(userTableName))
  }
}

/**
 * @description 存储用户信息
 * @param userInfo
 * @returns {void|*}
 */
export function setUserInfo(userInfo) {
  // 将部门id转化为数字，防止字符串和数字比较有问题
  userInfo.user.department_ids = userInfo.user.department_ids.map((val) => {
    return Number(val)
  })
  const userInfoObj = JSON.parse(JSON.stringify(userInfo))
  userInfo = JSON.stringify(userInfo)
  localStorage.setItem('backstage', userInfoObj.app_id)
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.setItem(userTableName, userInfo)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.setItem(userTableName, userInfo)
    } else {
      return localStorage.setItem(userTableName, userInfo)
    }
  } else {
    return localStorage.setItem(userTableName, userInfo)
  }
}

/**
 * @description 移除UserInfo
 * @returns {void|Promise<void>}
 */
export function removeUserInfo() {
  // localStorage.removeItem('backstage')  // 退出登录后移除切换后台权限
  if (storage) {
    if ('localStorage' === storage) {
      return localStorage.removeItem(userTableName)
    } else if ('sessionStorage' === storage) {
      return sessionStorage.clear()
    } else {
      return localStorage.removeItem(userTableName)
    }
  } else {
    return localStorage.removeItem(userTableName)
  }
}
