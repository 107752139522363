/**
 * @author https://gitee.com/chu1204505056/vue-admin-better （不想保留author可删除）
 * @description 路由守卫，目前两种模式：all模式与intelligence模式
 */
import router from '@/router'
import store from '@/store'
import VabProgress from 'nprogress'
import 'nprogress/nprogress.css'
import getPageTitle from '@/utils/pageTitle'
import netConfig from '@/config/net.config'
import { initRoute } from '@/router/modules/addRoute'

import {
  authentication,
  loginInterception,
  progressBar,
  recordRoute,
  routesWhiteList,
} from '@/config'

VabProgress.configure({
  easing: 'ease',
  speed: 500,
  trickleSpeed: 200,
  showSpinner: false,
})
router.beforeResolve(async (to, from, next) => {
  if (progressBar) VabProgress.start()
  let hasToken = store.getters['user/accessToken']

  if (!loginInterception) hasToken = true

  if (hasToken) {
    // console.info(to, store, '获取路由信息')
    if (to.path === '/login') {
      next({ path: '/' })
      if (progressBar) VabProgress.done()
    } else {
      const hasPermissions =
        store.getters['user/permissions'] &&
        store.getters['user/permissions'].length > 0
      if (hasPermissions) {
        next()
      } else {
        try {
          // 权限管理
          let permissions
          let v2Auth
          let v2AllAuth
          let v2getMyapp
          // let v2PcMenuLis
          if (!loginInterception) {
            //settings.js loginInterception为false时，创建虚拟权限
            await store.dispatch('user/setPermissions', ['admin'])
            permissions = ['admin']
            console.info('跑了未开启登录拦截器的区间？')
          } else {
            permissions = await store.dispatch('user/getUserInfo')
            // 同时获取所有权限列表，以及用户权限列表
            if (process.env.VUE_APP_TAG === 'paiplus') {
              v2getMyapp = await store.dispatch('routes/v2getMyapplication')
              v2AllAuth = await store.dispatch('routes/v2getPermissionsList')
              v2Auth = await store.dispatch('routes/v2getUserMenuList')
            }
            console.info(v2AllAuth, v2Auth, 'permission.js第51行')
          }
          // 动态路由
          let accessRoutes = []
          if (authentication === 'intelligence') {
            // v2动态路由
            // accessRoutes = await store.dispatch('routes/getPcMenuLis')
            // accessRoutes = await new Promise((resolve, reject) => {
            //   setTimeout(() => {
            //     accessRoutes = [
            //       {
            //         path: '/',
            //         component: 'layouts',
            //         redirect: { name: 'video' },
            //       },
            //       {
            //         path: '/content',
            //         name: 'Content',
            //         alwaysShow: true,
            //         component: 'layouts',
            //         meta: {
            //           title: '内容',
            //           icon: 'indent',
            //           auth: 'content',
            //         },
            //         children: [
            //           {
            //             path: 'material',
            //             component: '/routerIndex',
            //             name: 'material',
            //             alwaysShow: true,
            //             meta: {
            //               title: '素材',
            //               icon: 'indent',
            //               auth: 'material',
            //             },
            //             children: [
            //               {
            //                 path: 'video',
            //                 name: 'video',
            //                 component: '/video/index',
            //                 redirect: { name: 'videoList' },
            //                 meta: {
            //                   title: '视频',
            //                   icon: 'border-all',
            //                   auth: 'video',
            //                 },
            //                 children: [
            //                   {
            //                     path: 'videoList',
            //                     name: 'videoList',
            //                     component: '/video/videoManage/index',
            //                     meta: {
            //                       title: '视频列表',
            //                       icon: 'border-all',
            //                     },
            //                     // hidden: true,
            //                   },
            //                   {
            //                     path: 'edit',
            //                     name: 'editVideo',
            //                     component: '/video/videoManage/editVideo',
            //                     meta: {
            //                       title: '发布视频',
            //                       icon: 'border-all',
            //                       permissions: ['common'],
            //                       cache: false,
            //                     },
            //                     hidden: true,
            //                   },
            //                 ],
            //               },
            //             ],
            //           },
            //         ],
            //       },
            //     ]
            //     resolve(accessRoutes)
            //   }, 200)
            // })
            accessRoutes = await store.dispatch('routes/setRoutes', permissions)
          } else if (authentication === 'all') {
            accessRoutes = await store.dispatch('routes/setAllRoutes')
            console.info(accessRoutes, '所有菜单')
          }
          // v2动态路由
          // const asyncRouter = initRoute(accessRoutes)
          // store.commit('routes/setRoutes', asyncRouter)
          // asyncRouter.forEach((element) => {
          //   router.addRoute(element)
          // })
          accessRoutes.forEach((element) => {
            router.addRoute(element)
          })
          next({ ...to, replace: true })
        } catch {
          await store.dispatch('user/resetAccessToken')
          if (progressBar) VabProgress.done()
        }
      }
    }
  } else {
    if (routesWhiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      if (recordRoute) {
        next(`/login?redirect=${to.path}`)
      } else {
        next('/login')
      }

      if (progressBar) VabProgress.done()
    }
  }
  // document.title = getPageTitle(to.meta.title)
})
router.afterEach(() => {
  if (progressBar) VabProgress.done()
})
