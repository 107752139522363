/**
 * @description 导出默认网路配置
 *
 **/
const cdnHost =
  process.env.NODE_ENV === 'development' ? '' : process.env.VUE_APP_CDN_URL
const network = {
  // api地址，根据.env环境自动切换，请不要修改提交，否则线上打包会出现api接口域名错误
  baseURL: process.env.VUE_APP_API_URL,
  //配后端数据的接收方式application/json;charset=UTF-8或者application/x-www-form-urlencoded;charset=UTF-8
  contentType: 'application/json;charset=UTF-8',
  //消息框消失时间
  messageDuration: 3000,
  //最长请求时间
  requestTimeout: 15000,
  //操作正常code，支持String、Array、int多种类型
  successCode: [200, 0],
  //登录失效code
  invalidCode: 100,
  //无权限code
  noPermissionCode: 401,
  // CDN 本地跑一定要去掉CDN：cdnHost 正式环境打包要配上
  // cdnHost,
}
module.exports = network
